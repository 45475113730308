.defly-wallet-connect-modal {
  z-index: 9999;
}

/* This will hide all elements except the printable element */
@media print {
  body * {
    visibility: hidden;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    color: black !important;
  }
  .printable * {
    visibility: visible !important;
  }
  .printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
